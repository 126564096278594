<template>
	<component :is="currentJob.component" v-if="currentJob !== null" v-bind="currentJob.attrs" v-on="currentJob.listeners" @remove="onJobFinished()" />
</template>

<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'WDialogSingleton',
	mixins: [AppModuleGuard],
	data: function () {
		return {
			currentJob: null,
			isDialogOpened: false,
			queue: []
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [{ event: this.appEvents.DISPLAY_DIALOG, action: this.addJob }]
		},
		addJob: function (job) {
			if (this.isDialogOpened) {
				this.queue.push(job)
			} else {
				this.runJob(job)
			}
		},
		onJobFinished: function () {
			this.isDialogOpened = false
			this.currentJob = null
			if (this.queue.length > 0) {
				const job = this.queue.shift()
				this.runJob(job)
			}
		},
		runJob: function (job) {
			this.currentJob = job
			this.isDialogOpened = true
		}
	}
}
</script>
